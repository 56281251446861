

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL4PostLabPart5',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4_5: null,
      },
      questions: [
        {
          text: 'If the results of the data analysis showed that there is 1) more than one possible material, or 2) no conclusive material, what could you do to further determine the identity of the rocket piece?',
          inputName: 'part4_5',
        },
      ],
    };
  },
};
